import React from 'react';
import {
  Stack,
  Typography,
  Button, 
  Divider,
  List,
  ListItem,
  ListItemDecorator,
} from '@mui/joy';

import { useProfile } from '../../hooks/useProfile';
import { IconCircleCheck } from '@tabler/icons-react';

interface Props {
  showCheckout: () => void;
}

export default (props: Props) => {
  const { createSubscription } = useProfile();

  return (
    <Stack
      direction="column"
      gap={5}
      justifyContent="center"
      alignContent="center"
      sx={{
        minWidth: "100%",
      }}
    >
      <Stack direction="column" gap={1}>
        <Typography level="h1" sx={{ textAlign: "center" }}>
          Don't like limits?
        </Typography>
        <Typography level="body-lg" sx={{ textAlign: "center" }}>
          Upgrade to our <strong>Starter Plan</strong> to create up to 100 custom resumes each week
        </Typography>

        <Divider />
      </Stack>

      <Stack
        direction={{ xs: "column", md: "row" }}
        gap={5}
        justifyContent="center"
        alignContent="center"
        sx={{
          minWidth: "100%",
        }}
      > 
        <Stack
          direction="column"
          gap={2}
          justifyContent="center"
          alignContent="center"
          sx={{
            backgroundColor: "#F2F3F4",
            borderRadius: 12,
            p: 3,
          }}
        >
          <Typography level="h2">
            Starter Plan
          </Typography>

          <Typography level="body-md">
            Our starter plan is for people actively searching for the next step in their careers. With our starter plan, you can generate up to 100 customized resumes each week using our AI-powered resume generator. You can cancel anytime from your profile page.
          </Typography>

          <Stack
            direction="row"
            gap={1}
            justifyContent="flex-start"
            alignItems="center"
          >
            <Typography level="h2">
              $2.99
            </Typography>
            <Stack direction="column">
              <Typography level="body-xs">
                per
              </Typography>
              <Typography level="body-xs">
                week
              </Typography>
            </Stack>
          </Stack>

          <Button
            variant="solid"
            color="primary"
            onClick={props.showCheckout}
            sx={{
              borderRadius: 24
            }}
          >
            I'll take my plan without limits
          </Button>

          <List size="sm">
            <ListItem>
              <ListItemDecorator>
                <IconCircleCheck stroke={1} />
              </ListItemDecorator>
              <Typography level="body-xs">
                Comprehensive Career Canvas
              </Typography>
            </ListItem>
            <ListItem>
              <ListItemDecorator>
                <IconCircleCheck stroke={1} />
              </ListItemDecorator>
              <Typography level="body-xs">
                Simple job import
              </Typography>
            </ListItem>
            <ListItem>
              <ListItemDecorator>
                <IconCircleCheck stroke={1} />
              </ListItemDecorator>
              <Typography level="body-xs">
                Up to 100 custom resumes each week
              </Typography>
            </ListItem>
          </List>
        </Stack>

        <Stack
          direction="column"
          gap={2}
          justifyContent="center"
          alignContent="center"
          sx={{
            p: 3,
          }}
        >
          <Typography level="h2">
            Free Plan
          </Typography>

          <Typography level="body-md">
            Our free plan is for casual job-seekers. With our free plan, you can generate one customized resume each week using our AI-powered resume generator. In addition, we'll give you three additional when you sign up to get your job search started!
          </Typography>

          <Typography level="h2">
            Free
          </Typography>

          <Button
            variant="outlined"
            color="primary"
            onClick={createSubscription}
            sx={{
              borderRadius: 24
            }}
          >
            Take me to my free account
          </Button>

          <List size="sm">
            <ListItem>
              <ListItemDecorator>
                <IconCircleCheck stroke={1} />
              </ListItemDecorator>
              <Typography level="body-xs">
                Comprehensive Career Canvas
              </Typography>
            </ListItem>
            <ListItem>
              <ListItemDecorator>
                <IconCircleCheck stroke={1} />
              </ListItemDecorator>
              <Typography level="body-xs">
                Simple job import
              </Typography>
            </ListItem>
            <ListItem>
              <ListItemDecorator>
                <IconCircleCheck stroke={1} />
              </ListItemDecorator>
              <Typography level="body-xs">
                Three custom resumes when you sign up
              </Typography>
            </ListItem>
            <ListItem>
              <ListItemDecorator>
                <IconCircleCheck stroke={1} />
              </ListItemDecorator>
              <Typography level="body-xs">
                One additional custom resume each week
              </Typography>
            </ListItem>
          </List>
        </Stack>
      </Stack>

    </Stack>
  )
}
