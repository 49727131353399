import React, { useRef, useState, useEffect, useCallback, createContext, useContext, useMemo, useLayoutEffect } from 'react';

const createDeepEqual = ({ KEYS_TO_EXCLUDE = [] } = {}) => {
    // If comparing functions, this may need some work. Not sure the
    // best path for this: compare instance (what it currently does),
    // stringify and compare, etc.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const deepEqual = (a, b) => {
        // Ensures type is the same
        if (typeof a !== typeof b)
            return false;
        // arrays, null, and objects all have type 'object'
        if (a === null || b === null)
            return a === b;
        if (typeof a === 'object') {
            if (Object.keys(a).length !== Object.keys(b).length || Object.keys(a).some((k) => !(k in b)))
                return false;
            return Object.entries(a)
                .filter(([k]) => !KEYS_TO_EXCLUDE.includes(k))
                .every(([k, v]) => deepEqual(v, b[k]));
        }
        // boolean, string, number, undefined
        return a === b;
    };
    return deepEqual;
};

const deepEqual = createDeepEqual();
/**
 * Returns a version of `newValue` whose properties that are deeply equal to
 * those in `oldValue` are replaced with those from `oldValue`. This provides a
 * limited form of "structural sharing" that provides a stable reference for
 * unchanged slices of the object.
 *
 * If `oldValue` and `newValue` are referentially equal, the same value is
 * returned.
 *
 * @param oldValue The old value
 * @param newValue The new value
 */
const mergeWithStableProps = (oldValue, newValue) => {
    // If the values are already referentially the same, just return the new value
    if (oldValue === newValue) {
        return newValue;
    }
    return Object.keys(oldValue).reduce((acc, key) => {
        if (key in newValue && deepEqual(oldValue[key], newValue[key])) {
            acc[key] = oldValue[key];
        }
        return acc;
    }, Object.assign({}, newValue));
};

// useState can cause memory leaks if it is set after the component unmounted. For example, if it is
// set after `await`, or in a `then`, `catch`, or `finally`, or in a setTimout/setInterval.
const useAsyncState = (initialState) => {
    const isMounted = useRef(true);
    const [state, setState] = useState(initialState);
    useEffect(() => {
        isMounted.current = true;
        return () => {
            isMounted.current = false;
        };
    }, []);
    const setStateAction = useCallback((newState) => {
        isMounted.current && setState(newState);
    }, []);
    return [state, setStateAction];
};

const noProviderError = (item, provider = 'StytchProvider') => `${item} can only be used inside <${provider}>.`;
const providerMustBeUniqueError = 'You cannot render a <StytchProvider> inside another <StytchProvider>.';
const noSSRError = `The @stytch/react library is not meant for use with serverside environments like NextJS.
Use the @stytch/nextjs library instead - 
npm remove @stytch/react && npm install @stytch/nextjs
`;
const noHeadlessClientError = `Tried to create a Stytch Login UI element using the Stytch Headless SDK.
You must use the UI SDK to use UI elements.
Please make sure you are importing from @stytch/vanilla-js and not from the @stytch/vanilla-js/headless.`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function invariant(cond, message) {
    if (!cond)
        throw new Error(message);
}

const initialUser = {
    user: null,
    fromCache: false,
};
const initialSession = {
    session: null,
    fromCache: false,
};
const StytchContext = createContext({ isMounted: false });
const StytchUserContext = createContext(initialUser);
const StytchSessionContext = createContext(initialSession);
const useIsMounted__INTERNAL = () => useContext(StytchContext).isMounted;
const isUIClient = (client) => {
    return client.mountLogin !== undefined;
};
/**
 * Returns the active User.
 * Check the fromCache property to determine if the user data is from persistent storage.
 * @example
 * const {user} = useStytchUser();
 * return (<h1>Welcome, {user.name.first_name}</h1>);
 * @returns A {@link SWRUser}
 */
const useStytchUser = () => {
    invariant(useIsMounted__INTERNAL(), noProviderError('useStytchUser'));
    return useContext(StytchUserContext);
};
/**
 * Returns the active user's Stytch session.
 * @example
 * const {session} = useStytchSession();
 * useEffect(() => {
 *   if (!session) {
 *     router.replace('/login')
 *   }
 * }, [session]);
 * @returns A {@link SWRSession}
 */
const useStytchSession = () => {
    invariant(useIsMounted__INTERNAL(), noProviderError('useStytchSession'));
    return useContext(StytchSessionContext);
};
/**
 * Returns the Stytch client stored in the Stytch context.
 *
 * @example
 * const stytch = useStytch();
 * useEffect(() => {
 *   stytch.magicLinks.authenticate('...')
 * }, [stytch]);
 */
const useStytch = () => {
    const ctx = useContext(StytchContext);
    invariant(ctx.isMounted, noProviderError('useStytch'));
    return ctx.client;
};
const withStytch = (Component) => {
    const WithStytch = (props) => {
        invariant(useIsMounted__INTERNAL(), noProviderError('withStytch'));
        return React.createElement(Component, Object.assign({}, props, { stytch: useStytch() }));
    };
    WithStytch.displayName = `withStytch(${Component.displayName || Component.name || 'Component'})`;
    return WithStytch;
};
const withStytchUser = (Component) => {
    const WithStytchUser = (props) => {
        invariant(useIsMounted__INTERNAL(), noProviderError('withStytchUser'));
        const { user, fromCache } = useStytchUser();
        return React.createElement(Component, Object.assign({}, props, { stytchUser: user, stytchUserIsFromCache: fromCache }));
    };
    WithStytchUser.displayName = `withStytchUser(${Component.displayName || Component.name || 'Component'})`;
    return WithStytchUser;
};
const withStytchSession = (Component) => {
    const WithStytchSession = (props) => {
        invariant(useIsMounted__INTERNAL(), noProviderError('withStytchSession'));
        const { session, fromCache } = useStytchSession();
        return React.createElement(Component, Object.assign({}, props, { stytchSession: session, stytchSessionIsFromCache: fromCache }));
    };
    WithStytchSession.displayName = `withStytchSession(${Component.displayName || Component.name || 'Component'})`;
    return WithStytchSession;
};
/**
 * The Stytch Context Provider.
 * Wrap your application with this component in the root file in order to use Stytch everywhere in your app.
 * @example
 * const stytch = new StytchHeadlessClient('public-token-<find yours in the stytch dashboard>')
 *
 * ReactDOM.render(
 *   <StytchProvider stytch={stytch}>
 *     <App />
 *   </StytchProvider>,
 *   document.getElementById('root'),
 * )
 */
const StytchProvider = ({ stytch, children }) => {
    invariant(!useIsMounted__INTERNAL(), providerMustBeUniqueError);
    invariant(typeof window !== 'undefined', noSSRError);
    const ctx = useMemo(() => ({ client: stytch, isMounted: true }), [stytch]);
    const [{ user, session }, setClientState] = useAsyncState({
        session: stytch.session.getInfo(),
        user: stytch.user.getInfo(),
    });
    useEffect(() => stytch.onStateChange(() => {
        setClientState((oldState) => {
            const newState = {
                session: stytch.session.getInfo(),
                user: stytch.user.getInfo(),
            };
            return mergeWithStableProps(oldState, newState);
        });
    }), [setClientState, stytch]);
    return (React.createElement(StytchContext.Provider, { value: ctx },
        React.createElement(StytchUserContext.Provider, { value: user },
            React.createElement(StytchSessionContext.Provider, { value: session }, children))));
};

/**
 * The Stytch Login Screen component.
 * This component can only be used with a {@link StytchUIClient} client constructor
 * passed into the {@link StytchProvider}
 *
 * See the {@link https://stytch.com/docs/sdks/javascript-sdk online reference}
 * and {@link https://storybook.stytch.com interactive examples} for more.
 *
 * @example
 * <StytchLogin
 *   config={{
 *     products: ['emailMagicLinks', 'oauth'],
 *     emailMagicLinksOptions: {
 *       loginRedirectURL: 'https://example.com/authenticate',
 *       signupRedirectURL: 'https://example.com/authenticate',
 *     },
 *     oauthOptions: {
 *      providers: [{ type: OAuthProviders.Google }, { type: OAuthProviders.Microsoft }],
 *    },
 *   }}
 *   styles={{
 *     fontFamily: '"Helvetica New", Helvetica, sans-serif',
 *     primaryColor: '#0577CA',
 *     width: '321px',
 *   }}
 *   callbacks={{
 *     onEvent: (event) => console.log(event)
 *   }}
 * />
 * @param props {@link StytchProps}
 */
const StytchLogin = ({ config, styles, callbacks }) => {
    invariant(useIsMounted__INTERNAL(), noProviderError('<StytchLogin />'));
    const stytchClient = useStytch();
    const containerEl = useRef(null);
    useLayoutEffect(() => {
        if (!isUIClient(stytchClient)) {
            throw Error(noHeadlessClientError);
        }
        if (!containerEl.current) {
            return;
        }
        if (!containerEl.current.id) {
            const randId = Math.floor(Math.random() * 1e6);
            containerEl.current.id = `stytch-magic-link-${randId}`;
        }
        stytchClient.mountLogin({
            config: config,
            callbacks,
            elementId: `#${containerEl.current.id}`,
            styles,
        });
    }, [stytchClient, config, styles, callbacks]);
    return React.createElement("div", { ref: containerEl });
};
/**
 * The Stytch Reset Password component.
 * This component can only be used with a {@link StytchUIClient} client constructor
 * passed into the {@link StytchProvider}
 *
 * See the {@link https://stytch.com/docs/sdks/javascript-sdk online reference}
 * and {@link https://storybook.stytch.com interactive examples} for more.
 *
 * @example
 * <StytchPasswordReset
 *   config={{
 *     products: ['emailMagicLinks', 'oauth'],
 *     emailMagicLinksOptions: {
 *       loginRedirectURL: 'https://example.com/authenticate',
 *       signupRedirectURL: 'https://example.com/authenticate',
 *     },
 *     oauthOptions: {
 *      providers: [{ type: OAuthProviders.Google }, { type: OAuthProviders.Microsoft }],
 *    },
 *   }}
 *   passwordResetToken="PvC5UudZ7TPZbELt95yXAQ-8MeEUCRob8bUQ-g52fIJs"
 *   styles={{
 *     fontFamily: '"Helvetica New", Helvetica, sans-serif',
 *     primaryColor: '#0577CA',
 *     width: '321px',
 *   }}
 *   callbacks={{
 *     onEvent: (event) => console.log(event)
 *   }}
 * />
 *
 * @param config - A {@link StytchLoginConfig} object
 * @param passwordResetToken - A Stytch password reset token
 * @param styles - An optional {@link StyleConfig} to customize the look and feel of the screen.
 * @param callbacks - An optional {@link Callbacks} object
 */
const StytchPasswordReset = ({ config, styles, callbacks, passwordResetToken }) => {
    invariant(useIsMounted__INTERNAL(), noProviderError('<StytchResetPassword />'));
    const stytchClient = useStytch();
    const containerEl = useRef(null);
    useLayoutEffect(() => {
        if (!isUIClient(stytchClient)) {
            throw Error(noHeadlessClientError);
        }
        if (!containerEl.current) {
            return;
        }
        if (!containerEl.current.id) {
            const randId = Math.floor(Math.random() * 1e6);
            containerEl.current.id = `stytch-reset-password-${randId}`;
        }
        if (passwordResetToken) {
            stytchClient.mountResetPassword({
                config: config,
                callbacks,
                elementId: `#${containerEl.current.id}`,
                styles,
                passwordResetToken,
            });
        }
    }, [stytchClient, config, styles, callbacks, passwordResetToken]);
    return React.createElement("div", { ref: containerEl });
};
const StytchPasskeyRegistration = ({ config, styles, callbacks }) => {
    /**
     * The Stytch Passkey Registration component.
     * This component can only be used with a {@link StytchUIClient} client constructor
     * passed into the {@link StytchProvider}
     *
     * See the {@link https://stytch.com/docs/sdks/javascript-sdk online reference}
     *
     *
     * @example
     * const styles = {
     *     container: {
     *       backgroundColor: '#e11e1e',
     *     },
     *     colors: {
     *       primary: '#ff00f7',
     *       secondary: '#5C727D',
     *     },
     *    }
     *
     * <StytchPasskeyRegistration
     *   config={{
     *      products: ['passkey'],
     *    }}
     *   styles={styles}
     *   callbacks={{
     *     onEvent: (event) => console.log(event)
     *   }}
     * />
     * @param config - A {@link StytchLoginConfig} object
     * @param styles - An optional {@link StyleConfig} to customize the look and feel of the screen.
     * @param callbacks - An optional {@link Callbacks} object
     */
    invariant(useIsMounted__INTERNAL(), noProviderError('<StytchPasskeyRegistration />'));
    const stytchClient = useStytch();
    const user = useStytchUser();
    const containerEl = useRef(null);
    useLayoutEffect(() => {
        if (!isUIClient(stytchClient)) {
            throw Error(noHeadlessClientError);
        }
        if (!containerEl.current) {
            return;
        }
        if (!containerEl.current.id) {
            const randId = Math.floor(Math.random() * 1e6);
            containerEl.current.id = `stytch-reset-passkey-${randId}`;
        }
        stytchClient.mountPasskeyRegistration({
            config,
            callbacks,
            elementId: `#${containerEl.current.id}`,
            styles,
        });
    }, [stytchClient, config, styles, callbacks, user]);
    return React.createElement("div", { ref: containerEl });
};

export { StytchLogin, StytchPasskeyRegistration, StytchPasswordReset, StytchProvider, useStytch, useStytchSession, useStytchUser, withStytch, withStytchSession, withStytchUser };
