import React from 'react';
import { Link } from 'react-router-dom';
import {
  Stack,
  IconButton,
  Button,
  Modal,
  ModalDialog,
  Typography,
  Box
} from '@mui/joy'
import { IconX } from '@tabler/icons-react'

interface Props {
  open: boolean;
  close: () => void;
}

export default (props: Props) => {
  return (
    <Modal
      open={props.open}
      onClose={props.close}
    >
      <ModalDialog>
        <Stack
          direction="column"
          alignContent="flex-start"
          justifyContent="flex-start"
          gap={2}
          sx={{
            width: '100%',
            mb: 2,
          }}
        >
          <Stack
            direction="row"
            alignContent="center"
            justifyContent="flex-end"
            sx={{
              width: '100%',
            }}
          >
            <IconButton
              variant="plain"
              onClick={() => props.close()}
            >
              <IconX stroke={1} />
            </IconButton>
          </Stack>

          <Box>
            <Typography level="h3">Congratulations!</Typography>
            <Typography level="title-lg">You've just imported your Career Canvas!</Typography>
          </Box>

          <Typography level="body-md">
            Now that you've imported your Career Canvas, lets import some job listings, and create a custom resume for you!
          </Typography>

          <Link to="/">
            <Button
              variant="solid"
              color="primary"
              sx={{
                borderRadius: 24,
              }}
            >
              Import Job Listings
            </Button>
          </Link>
        </Stack>
      </ModalDialog>
    </Modal>
  );
}